
import { Vue, Options, prop } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import RadioButton from "primevue/radiobutton";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { ReportController } from "@/app/ui/controllers/ReportController";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import { reportTypeToTitle } from "@/app/infrastructures/misc/Constants";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

import {
  ClientMappingList,
  RequestApiClientMappingList
} from "@/domain/entities/Client";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { ReportApiRequest } from "@/domain/entities/Report";
import debounce from "lodash/debounce";
import { PosController } from "@/app/ui/controllers/PosController";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { PosData } from "@/domain/entities/Pos";
import { 
  showClientPartner,
  showPartner,
  showReportType,
  showOriginCity,
  clientPartnerTitle,
  validationMonthlySelected,
  disableBasedOnDescription,
  usingDateRange
} from "./modules/conditions"

interface Data {
  description: string;
  color: string;
}
class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  data = prop<Data>({
    type: Object,
    default: null
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {
    ModalForm,
    RadioButton
  }
})
export default class ModalDownload extends Vue.with(Props) {
  onClose() {
    this.$emit("update:modelValue", false);
    this.onReset();
  }

  mounted() {
    this.onReset();
  }
  updated() {
    if (this.isInternal || this.isClient) {
      this.bookedFor = "client";
    } else if (this.isInternal || this.isPos) {
      this.bookedFor = "pos";
    }
    this.reportType = "outbound";
    this.isSearch = false;
  }

  onReset() {
    this.bookedFor = "";
    this.reportType = "";
    this.isSearch = false;
    this.shortlandName = "";
    this.shortlandValue = "";
    this.cityDisplay = "";
    this.cityValue = 0;
    this.citySearchValue = "";
    this.clientNameDisplay = "";
    this.scrapValue = "";
    this.cancelValue = "";
    this.misrouteValue = "";
    this.startDate = "";
    this.sttStatusValue = "";
    this.sttStatusName = "";
    this.partnerNameDisplay = "";
    this.topupTypeValue = "";
    this.isErrorDateSelectedPerMonth = false;
    this.posParentOrBranchSelected = "";
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // get account type
  get isInternal() {
    return this.dataProfile.isInternalAccount || this.dataProfile.isCustomerService;
  }
  get isClient() {
    return this.dataProfile.isAllClient;
  }
  get isClientParent() {
    return this.dataProfile.isClientParentAccount;
  }
  get isPos() {
    return this.dataProfile.isPosAccount;
  }
  get isConsole() {
    return this.dataProfile.isConsoleAccount;
  }

  get isPosBranch() {
    return this.dataProfile.isPosChildAccount;
  }

  get isPosParent() {
    return this.dataProfile.isPosParentAccount;
  }

  get isKomisiAffiliatePos() {
    return this.data.description === "Komisi Affiliate" && this.isPos;
  }

  // show component
  // booked for
  get isShowBookedFor() {
    const reportType = ["stt", "transaksi", "fpr", "stt manual"];

    if (this.data.description === "Shortland") {
      return false;
    }
    return (
      this.isInternal &&
      reportType.includes(this.data.description.toLowerCase())
    );
  }
  // client partner
  get isShowClientPartner() {
    if (showClientPartner(this.data.description)) {
      return false;
    }
    return this.isInternal || this.isConsole || this.isClient || this.isPos;
  }
  get isShowClientPartnerDefault() {
    if (this.data.description === "Shortland") {
      return false;
    }
    return (
      this.isConsole ||
      this.isPosBranch ||
      this.isPosParent ||
      (this.isClient && !this.isClientParent)
    );
  }

  get isShowPosParentDefault() {
    return this.isPosParent;
  }

  get isShowPosParent() {
    return this.isPos;
  }

  // partner only
  get isShowPartner() {
    if (showPartner(this.data.description) || this.isKomisiAffiliatePos) {
      return false;
    }
    return this.isInternal || this.isConsole || this.isPosBranch;
  }

  // report type
  get isShowReportType() {
    if (showReportType(this.data.description)) {
      return false;
    }
    return this.isInternal || this.isConsole;
  }

  // origin city
  get isShowOriginCity() {
    if (this.data.description === "Misroute" && this.isInternal) {
      return true;
    } else if (showOriginCity(this.data.description, this.isConsole, this.isInternal)) {
      return false;
    }
    return this.isInternal || this.isShowOriginDefault;
  }
  get isShowOriginDefault() {
    if (this.data.description === "Misroute") {
      return false;
    }
    return this.isConsole || this.isPos;
  }

  // booked for
  bookedFor = "";
  get bookedForList() {
    return [
      {
        name:
          this.data.description === "STT Manual" ? "Client Cabang" : "Client",
        value: "client"
      },
      { name: "POS", value: "pos" }
    ];
  }
  onBookedFor() {
    this.clientNameDisplay = "";
  }

  // partner name
  partnerNameDisplay: any = "";
  partnerNameSearchValue = "";
  onSearchPartnerName(value: string) {
    this.isSearch = false;

    if (!value || value.length >= 3) {
      this.clientNameSearchValue = value;
      if (this.isClientParent) {
        ClientController._onGetCLientMappingList(
          new RequestApiClientMappingList({
            page: 1,
            limit: 10,
            search: value,
            isBranch: this.isClientParent
          })
        );
      } else {
        ClientController._onGetListPartner({
          page: 1,
          limit: 10,
          search: value,
          partnerType: "pos",
          type: "partner",
          isBranch: this.isClientParent,
          isParent: this.isClientParent
        });
      }
    }
  }
  get partnerNameList() {
    const allOptions = [
      { id: 0, name: `Semua Partner`, code: "all", type: "" }
    ];

    const data = this.isClientParent
      ? this.mappedDateClient
      : ClientController.clientPartnerData;

    if (!this.clientNameSearchValue && data.length) {
      return [...allOptions, ...data];
    } else if (this.clientNameSearchValue) {
      return data;
    }
    return [];
  }
  get isLoadingPartner() {
    return ClientController.isLoading;
  }

  // Client Name
  get clientPartnerTitle() {
    if (clientPartnerTitle(this.data.description, this.isPos, this.isConsole)) {
      return this.$t('report.cards.colPartnerName');
    } else if (this.isClient) {
      if (this.data.description === "STT Manual") {
        return "Nama Client";
      }
      return "Dibooking Untuk";
    }
    return "Nama Client/Partner";
  }
  clientNameDisplay = "";
  clientNameSearchValue = "";
  get isClientNameDisabled() {
    return !this.bookedFor || this.isConsole;
  }
  onSearchClientName(value: string) {
    this.isSearch = false;

    if (!value || value.length >= 3) {
      this.clientNameSearchValue = value;
      if (this.isClientParent) {
        ClientController._onGetCLientMappingList(
          new RequestApiClientMappingList({
            page: 1,
            limit: 10,
            search: value,
            isBranch: this.isClientParent
          })
        );
      } else {
        ClientController._onGetListPartner({
          page: 1,
          limit: 10,
          search: value,
          partnerType: this.bookedFor,
          type: this.bookedFor === "pos" ? "partner" : this.bookedFor,
          isBranch:
            this.data.description.toLowerCase() === "stt manual" &&
            this.bookedFor === "client"
              ? true
              : this.isClientParent,
          isParent: this.isClientParent,
          orderBy: "name",
          sortBy: "asc"
        });
      }
    }
  }
  get mappedDateClient() {
    return ClientController.clientMappingData.map(
      (client: ClientMappingList) => ({
        id: client.clientId,
        name: client.clientName,
        code: client.clientCode
      })
    );
  }
  get clientNameList() {
    const data = this.isClientParent
      ? this.mappedDateClient
      : ClientController.clientPartnerData;
    const allClientPOS =
      this.bookedFor === "client"
        ? {
            id: 0,
            name: this.$t('report.cards.labelAllClient'),
            code: "all"
          }
        : {
            id: 0,
            name: this.$t('report.cards.labelAllPOS'),
            code: "all"
          };
    if (!this.clientNameSearchValue && data.length) {
      return [allClientPOS, ...data];
    } else if (this.clientNameSearchValue) {
      return data;
    }
    return [];
  }
  get isLoadingPartnerClient() {
    return ClientController.isLoading;
  }

  // report type
  reportType = "";
  get isReportTypeDisabled() {
    return this.isPos || this.isClient;
  }
  get reportTypeList() {
    const defaultList = { name: "Outbound", value: "outbound" };
    if (!this.isReportTypeDisabled) {
      return [defaultList, { name: "Inbound", value: "inbound" }];
    }
    return [defaultList];
  }

  // city
  get originCity() {
    return `${this.dataProfile.account_location.city_code} - ${this.dataProfile.account_location.city_name}`;
  }
  cityDisplay: any = "";
  cityValue = 0;
  citySearchValue = "";
  onSearchCity(search: string) {
    this.isSearch = false;
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    this.citySearchValue = search;

    if (!search.length || search.length > 2) {
      LocationController.getCityList({
        page: 1,
        limit: 10,
        search: search,
        status: ""
      });
    }
  }
  get cityList() {
    const allOptions = [{ id: 0, name: "ALL", code: "all" }];
    const listData = LocationController.cityData.cityData
      .filter(item => item.name !== "")
      .map(item => {
        return { ...item, name: `${item.code} - ${item.name}` };
      });

    if (!this.citySearchValue && listData.length) {
      return [...allOptions, ...listData];
    } else if (this.citySearchValue) {
      return listData;
    }
    return [];
  }
  get isLoadingCity() {
    return LocationController.isLoading;
  }

  // date
  startDate = "";
  endDate = "";
  get periodeStart() {
    return this.startDate;
  }
  get periodeEnd() {
    return this.endDate;
  }
  setPeriode(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    this.isSearch = false;
    this.startDate = value[0];
    this.endDate = value[1];
  }
  get converDate() {
    return `${dateToDateString(this.startDate, true)}`;
  }

  // filter date
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  get rangeLimit() {
    switch (this.data.description) {
      case "STT":
        return 30;
      case "Pengiriman Keluar (Kota Asal)":
        return 2;
      default:
        return 6;
    }
  }
  setDay(day: any) {
    if (this.isStartDate) {
      const rangeLimit = this.rangeLimit;
      this.tempStartDate = this.startDate;
      this.tempEndDate = this.endDate;
      this.startDate = "";
      this.endDate = "";
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - rangeLimit)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + rangeLimit)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }
  rangeOptions() {
    if (this.data.description === "STT") {
      return `${this.$t('custom')}, ${this.$t('kemarin')}, ${this.$t('hari ini')}, ${this.$t('7 hari terakhir')}, ${this.$t('31 hari terakhir')}`;
    } else if (this.data.description === "Pengiriman Keluar (Kota Asal)") {
      return "custom, kemarin, hari ini, 3 hari terakhir";
    } else if (this.data.description === "Bonus Komisi") {
      return "custom";
    } else {
      return "custom, kemarin, hari ini, 7 hari terakhir";
    }
  }
  get maxRangeDatePicker() {
    if (this.maxDate === null) {
      if (this.data.description === "Bonus Komisi") {
        return this.$moment(new Date()).endOf("month");
      } else {
        return new Date();
      }
    }
    return this.$moment(this.maxDate).isAfter(this.$moment())
      ? new Date()
      : this.maxDate;
  }

  isErrorDateSelectedPerMonth = false;
  validateSelectPerMonth() {
    if (this.data.description === "Bonus Komisi" && this.startDate) {
      const startDate = this.$moment(this.startDate).date();
      const endDate = this.$moment(this.endDate).date();

      const startMonth = this.$moment(this.startDate).month();
      const endMonth = this.$moment(this.endDate).month();

      const startYear = this.$moment(this.startDate).year();
      const endYear = this.$moment(this.endDate).year();

      const lastDateOfTheMonth = this.$moment(this.startDate)
        .endOf("month")
        .date();

      const allDate = {
        startDate,
        endDate,
        lastDateOfTheMonth,
        startMonth,
        endMonth,
        startYear,
        endYear
      }

      if (validationMonthlySelected(allDate)) {
        this.isErrorDateSelectedPerMonth = false;
      } else {
        this.isErrorDateSelectedPerMonth = true;
      }
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      this.isStartDate = true;
      this.startDate = this.tempStartDate;
      this.endDate = this.tempEndDate;
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }

  // download search
  isSearch = false;
  isError = false;

  get isProcessing() {
    const data = ReportController.listDownloadReport.data.map(item => {
      const addition = {
        title: `${reportTypeToTitle[item.type] || item.type}`
      };
      return { ...item, ...addition };
    });
    return !!data.find(
      item => item.title === this.data.description && item.status === "waiting"
    );
  }

  async onDownloadCsv(isCheck: boolean) {
    const clientPartner: any = this.clientNameDisplay;
    const partner: any = this.partnerNameDisplay;
    const city =
      typeof this.cityDisplay === "object"
        ? this.cityDisplay.code
        : this.dataProfile.account_location.city_code;
    const originCity = this.cancelValue === "origin" ? city : "";
    const destinationCity = this.cancelValue === "destination" ? city : "";
    let title = "";
    let fileName = "";

    let async = false;
    let version = "1";

    switch (this.data.description) {
      case "STT":
        title = "stt";
        version = "4";
        isCheck = false;
        async = true;
        break;
      case "STT Manual":
        title = "manual-stt";
        this.reportType = "";
        this.shortlandValue = this.sttStatusValue;
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "FPR":
        title = "fpr";
        break;
      case "Transaksi":
        title = "transaksi";
        break;
      case "Misroute":
        title = "missing-route";
        fileName = "misroute";
        this.reportType = "";
        this.bookedFor = "";
        version = "3";
        isCheck = false;
        async = true;
        break;
      case "Shortland":
        title = "shortland";
        this.reportType = "";
        this.bookedFor = "";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Scrap":
        title = "scrap";
        this.reportType = "";
        this.bookedFor = "";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Pengiriman":
        title = "shipment";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Pengiriman Keluar (Kota Asal)":
        title = "shipment-outgoing";
        this.reportType = "";
        this.bookedFor = "";
        version = "3";
        isCheck = false;
        async = true;
        break;
      case "Pengiriman Masuk (Kota Tujuan)":
        title = "sti-dest";
        fileName = "destination_incoming";
        this.reportType = "";
        this.bookedFor = "";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Pembatalan STT":
        title = "cancel";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Top Up":
        title = "top_up";
        this.reportType = "";
        this.bookedFor = "";
        break;
      case "Komisi Pengiriman":
        title = "dtpol-fee";
        isCheck = false;
        version = "2";
        async = true;
        break;
      case "DTPOL Tier X":
        title = "tier-x";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "STT Client":
        title = "simple-stt";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Referral POS":
        this.reportType = "";
        this.bookedFor = "";
        title = "referral-report";
        isCheck = false;
        async = true;
        break;
      case "Bonus Komisi":
        title = "progressive-commission-report";
        this.reportType = "";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Komisi Affiliate":
        title = "pos-affiliate";
        version = "2";
        isCheck = false;
        async = true;
        break;
      case "Komisi POS Induk":
        title = "pos-parent";
        version = "1";
        isCheck = false;
        async = true;
        break;
      default:
        break;
    }

    let bookedForId = "";
    if (!this.isConsole && this.isShowClientPartnerDefault) {
      bookedForId = String(this.dataProfile.account_type_detail.id);
    } else if (this.isShowPosParent) {
      bookedForId = String(this.dataProfile.account_type_detail.id);
    } else if (clientPartner?.code?.toLowerCase() === "all") {
      bookedForId = clientPartner.code;
    } else if (clientPartner.id) {
      bookedForId = String(clientPartner.id);
    }

    let paramsRequest: ReportApiRequest;
    switch (title) {
      case "dtpol-fee":
        paramsRequest = new ReportApiRequest({
          title: title,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(this.endDate).toLocaleDateString("fr-CA"),
          deliveredByType: this.isInternal
            ? this.deliveryBy
            : this.dataProfile.account_type_detail.type,
          deliveredById: this.isInternal
            ? this.partnerNameDisplay?.id || ""
            : this.dataProfile.account_type_detail.id,
          cityDestinationCode: city,
          version: version,
          async: async
        });
        break;
      case "tier-x":
        paramsRequest = new ReportApiRequest({
          title: title,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(this.endDate).toLocaleDateString("fr-CA"),
          version: version,
          async: async
        });
        break;
      case "simple-stt":
        paramsRequest = new ReportApiRequest({
          title: title,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(
            this.isUsingDateRange ? this.endDate : this.startDate
          ).toLocaleDateString("fr-CA"),
          bookedForId: bookedForId,
          async: async,
          version: version
        });
        break;
      case "referral-report":
        paramsRequest = new ReportApiRequest({
          service: "horde",
          title: title,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(
            this.isUsingDateRange ? this.endDate : this.startDate
          ).toLocaleDateString("fr-CA"),
          bookedForId: bookedForId,
          async: async,
          version: version,
          feature: "partner"
        });
        break;
      case "progressive-commission-report":
        paramsRequest = new ReportApiRequest({
          service: "hydra",
          title: title,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(
            this.isUsingDateRange ? this.endDate : this.startDate
          ).toLocaleDateString("fr-CA"),
          async: async,
          version: version,
          posId: partner.id || "",
          extension: "xlsx"
        });
        break;
      case "pos-parent":
        paramsRequest = new ReportApiRequest({
          service: "hydra",
          title: title,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(
            this.isUsingDateRange ? this.endDate : this.startDate
          ).toLocaleDateString("fr-CA"),
          async: async,
          version: version,
          posParentId: this.isInternal
            ? this.posParentOrBranchSelected?.id
            : this.dataProfile.account_type_detail.id,
          extension: "xlsx"
        });
        break;
      case "pos-affiliate":
        paramsRequest = new ReportApiRequest({
          service: "hydra",
          title: title,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(
            this.isUsingDateRange ? this.endDate : this.startDate
          ).toLocaleDateString("fr-CA"),
          async: async,
          version: version,
          partnerId: partner.id || ""
        });
        break;
      default:
        paramsRequest = new ReportApiRequest({
          service: this.data.description === "Top Up" ? "gober" : "hydra",
          title: title,
          isCheck: isCheck,
          fileName: fileName,
          startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
          endDate: new Date(
            this.isUsingDateRange ? this.endDate : this.startDate
          ).toLocaleDateString("fr-CA"),
          reportType: this.reportType,
          bookedForType: this.bookedFor,
          bookedForId: bookedForId,
          cityCode: city === "all" ? "" : city,
          status: this.shortlandValue,
          scrapBased: this.scrapValue,
          missRouteBase: this.misrouteValue === "all" ? "" : this.misrouteValue,
          originCity: originCity === "all" ? "" : originCity,
          destinationCity: destinationCity === "all" ? "" : destinationCity,
          partnerId: partner.id || "",
          type: this.topupTypeValue,
          extension: this.data.description === "Top Up" ? "xlsx" : "csv",
          version: version,
          manualSttForType:
            this.bookedFor === "pos" ? "partner" : this.bookedFor,
          manualSttForId: bookedForId,
          bookedByPosBranchId:
            this.posParentOrBranchSelected?.posType === "POS Cabang"
              ? String(this.posParentOrBranchSelected?.id)
              : this.posParentOrBranchSelected?.posType === "All"
              ? "all"
              : "0",
          async: async
        });
        break;
    }

    const res: any = await ReportController.getDownloadReport(paramsRequest);

    // check data
    if (res) {
      this.isSearch = true;
      this.isError = res.code !== 200;
    }

    // download file success
    if (!isCheck) {
      this.onClose();
    }
  }

  // cancel based
  cancelName = "";
  cancelValue = "";
  cancelData = [
    { name: "Pilih jenis", value: "" },
    { name: "Origin City", value: "origin" },
    { name: "Destination City", value: "destination" }
  ];
  cancelSelect = false;
  onOpenCancelSelect() {
    this.isSearch = false;
    this.cancelSelect = true;
  }
  onCloseCancelSelect() {
    this.cancelSelect = false;
  }
  onSelectCancel(name: string, value: string) {
    this.cancelName = name;
    this.cancelValue = value;
    this.onCloseCancelSelect();
  }

  // shortland based
  shortlandName = "";
  shortlandValue = "";
  shortlandData = [
    { name: "Pilih jenis", value: "" },
    { name: "Origin City", value: "origin_city" },
    { name: "Destination City", value: "destination_city" },
    { name: "Shortland City", value: "shortland_city" }
  ];
  shortlandSelect = false;
  onOpenshortlandSelect() {
    this.isSearch = false;
    this.shortlandSelect = true;
  }
  onCloseshortlandSelect() {
    this.shortlandSelect = false;
  }
  onSelectshortland(name: string, value: string) {
    this.shortlandName = name;
    this.shortlandValue = value;
    this.onCloseshortlandSelect();
  }

  // misroute based
  misrouteName = "";
  misrouteValue = "";
  misrouteData = [
    { name: "Pilih tipe", value: "" },
    { name: "Origin City", value: "origin" },
    { name: "Destination City", value: "destination" },
    { name: "Misroute City", value: "misroute_city" }
  ];
  misrouteSelect = false;
  onOpenMisrouteSelect() {
    this.isSearch = false;
    this.misrouteSelect = true;
  }
  onCloseMisrouteSelect() {
    this.misrouteSelect = false;
  }
  onSelectMisroute(name: string, value: string) {
    this.misrouteName = name;
    this.misrouteValue = value;
    this.onCloseMisrouteSelect();
  }

  // scrap based
  scrapName = "";
  scrapValue = "";
  scrapData = [
    { name: "Pilih jenis", value: "" },
    { name: "Origin City", value: "origin" },
    { name: "Destination City", value: "destination" }
  ];
  scrapSelect = false;
  onOpenScrapSelect() {
    this.isSearch = false;
    this.scrapSelect = true;
  }
  onCloseScrapSelect() {
    this.scrapSelect = false;
  }
  onSelectScrap(name: string, value: string) {
    this.scrapName = name;
    this.scrapValue = value;
    this.onCloseScrapSelect();
  }

  // top up type
  topupTypeName = "";
  topupTypeValue = "";
  topupTypeData = [
    { name: "Pilih jenis", value: "" },
    { name: "Top Up Virtual Account", value: "top_up" },
    { name: "Top Up Manual", value: "top_up_manual_approved" }
  ];
  topupTypeSelect = false;
  onOpenTopupTypeSelect() {
    this.isSearch = false;
    this.topupTypeSelect = true;
  }
  onCloseTopupTypeSelect() {
    this.topupTypeSelect = false;
  }
  onSelectTopupType(name: string, value: string) {
    this.topupTypeName = name;
    this.topupTypeValue = value;
    this.onCloseTopupTypeSelect();
  }

  // STT Status
  sttStatusName = "";
  sttStatusValue = "";
  sttStatusData = [
    { name: "Pilih tipe", value: "" },
    { name: "Used", value: "used" },
    { name: "Unused", value: "unused" }
  ];
  sttStatusSelect = false;
  onOpenSttStatusSelect() {
    this.isSearch = false;
    this.sttStatusSelect = true;
  }
  onCloseSttStatusSelect() {
    this.sttStatusSelect = false;
  }
  onSelectSttStatus(name: string, value: string) {
    this.sttStatusName = name;
    this.sttStatusValue = value;
    this.onCloseSttStatusSelect();
  }

  // disabled button search
  get isDisabled() {
    const clientPos: any = this.clientNameDisplay;
    const partnerPos: any = this.partnerNameDisplay;
    const city: any = this.cityDisplay;
    const isDate = !this.periodeStart;
    const description = this.data.description?.toLowerCase();
    const isClientBranch = this.isClient && !this.isClientParent;

    if (description === "stt" || description === "stt client") {
      if (this.isInternal) {
        return (
          !this.bookedFor ||
          !clientPos.code ||
          !this.reportType ||
          !city.code ||
          isDate
        );
      } else if (this.isConsole || isClientBranch) {
        return (
          !this.dataProfile.name ||
          (!isClientBranch && (!this.reportType || !this.originCity)) ||
          isDate
        );
      } else if (this.isPos) {
        const validatePos = this.isPosBranch || this.isPosParent
          ? false
          : !this.posParentOrBranchSelected;
        return (
          !this.dataProfile.name || !this.originCity || isDate || validatePos
        );
      } else {
        return !clientPos.code || isDate;
      }
      // if have select status / type
    } else if (disableBasedOnDescription(description)) {
      return (
        (this.isInternal ? !city.code : !this.originCity) ||
        !(this.cancelValue || this.scrapValue || this.shortlandValue) ||
        isDate
      );
      // if no select status / type
    } else if (description.includes("pengiriman")) {
      if (description === "Komisi Pengiriman") {
        if (this.isInternal) {
          return !partnerPos.id || !city.code;
        }
        return false;
      }
      return (this.isInternal ? !city.code : !this.originCity) || isDate;
    } else if (description === "misroute") {
      return (
        (this.isInternal ? !city.code : !this.originCity) || !this.misrouteValue
      );
    } else if (description === "stt manual") {
      return (
        !this.sttStatusValue ||
        (!this.bookedFor && !this.isConsole) ||
        (!clientPos.code && this.isInternal) ||
        isDate
      );
    } else if (description === "top up") {
      return isDate || !partnerPos.code || !this.topupTypeValue;
    } else if (description === "DTPOL Tier X") {
      return isDate;
    } else if (description === "Referral POS") {
      return isDate;
    } else if (description === "bonus komisi") {
      if (this.isInternal) {
        return isDate || !partnerPos.code || this.isErrorDateSelectedPerMonth;
      } else {
        return isDate || this.isErrorDateSelectedPerMonth;
      }
    } else if (description === "komisi affiliate") {
      if (this.isInternal) {
        return !partnerPos.name;
      }
    } else if (description === "komisi pos induk") {
      if (this.isInternal) {
        return !this.posParentOrBranchSelected || isDate;
      } else {
        return !this.dataProfile.name || isDate;
      }
    }
    return false;
  }

  // delivery by
  deliveryBy = "pos";
  get isDeliveryBy() {
    return this.isInternal && this.data.description === "Komisi Pengiriman";
  }
  get deliveryByOptions() {
    return [
      { name: "Console", value: "console" },
      { name: "Sub Console", value: "sub-console" },
      { name: "POS", value: "pos" }
    ];
  }
  get deliveryByNameSelected() {
    return (
      this.deliveryByOptions.find(
        item =>
          item.value ===
          (this.isInternal
            ? this.deliveryBy
            : this.dataProfile.account_type_detail.type)
      )?.name || ""
    );
  }

  // fetch partners
  isLoadingFetchPartner = false;
  listPartner: any = [];
  get isFetchPartner() {
    return (
      this.data.description === "Komisi Pengiriman" ||
      (this.data.description === "DTPOL Tier X" && this.isConsole)
    );
  }
  async fetchPartner(search: string) {
    this.isLoadingFetchPartner = true;
    this.listPartner = await PartnerController.fetchPartnerApi({
      page: 1,
      limit: 10,
      search: search,
      type: this.deliveryBy,
      cityCode: this.cityDisplay ? this.cityDisplay.code.toUpperCase() : ""
    });
    this.isLoadingFetchPartner = false;
  }
  filterFetchPartner = debounce(async (search: string) => {
    await this.fetchPartner(search);
  }, 250);
  get fetchPartnerOptions() {
    if (this.listPartner.length === 0) {
      return [];
    }
    return [
      {
        name: `All ${this.deliveryByNameSelected}`,
        id: "0"
      },
      ...this.listPartner
    ];
  }

  get defaultDateRange() {
    if (this.data.description === "Pengiriman Keluar (Kota Asal)") {
      return "3 hari terakhir";
    } else if (this.data.description === "Bonus Komisi") {
      return "satu bulan";
    } else {
      return this.$t('7 hari terakhir')
    }
  }

  get isUsingDateRange() {
    return (usingDateRange(this.data.description));
  }

  get lastSyncSttManual() {
    return formatDate(ReportController.lastSync.lastUpdatedAt);
  }

  get loadingSyncSttManual() {
    return ReportController.isLoading;
  }

  posParentOrBranchSelected: any = "";
  onSearchPosBranch = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        type: "pos",
        search: search,
        posType: "branch",
        posParentId: this.dataProfile.account_type_detail.id
      })
    );
  }, 250);

  get isLoadingPosParentOrBranch() {
    return PosController.isLoading;
  }

  get posParentOrBranch() {
    const defaultOptions = [
      {
        id: "all",
        name: this.$t('report.cards.labelAllPOS'),
        posType: "All"
      },
      {
        id: this.dataProfile.account_type_detail.id,
        name: this.dataProfile.name,
        posType: "POS Parent"
      }
    ];

    const pos = PosController.posList.data.map((item: PosData) => ({
      id: item.id,
      name: item.name,
      posType: item.posType
    }));

    return this.data.description === "Komisi POS Induk"
      ? pos
      : [...defaultOptions, ...pos];
  }

  // pos parent
  get isPosParentOptions() {
    return this.data.description === "Komisi POS Induk";
  }

  onSearchPosParent = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        type: "pos",
        search: search,
        posType: "parent"
      })
    );
  }, 250);
}
